import axiosInstance from "../utils/axiosInstance";

const accountModule = `/business/account`;
const storeModule = `/business/stores/store`;
const orderModule = `/business/stores/order`;
const bankModule = `/business/stores/bank`;
const withdrawalModule = `/business/stores/withdrawal`;
const cartModule = `/business/stores/cart`;
const productModule = `/business/stores/product`;
const subscriptionModule = `/business/stores/subscription`;

export const createUserMutation = userDetails => {
  return axiosInstance.post(`${accountModule}/user/create`, userDetails);
};

export const activateUserMutation = info => {
  return axiosInstance.post(`${accountModule}/user/activate-account`, info);
};

export const resendActivationCodeMutation = code => {
  return axiosInstance.post(`${accountModule}/user/activate-account`, code);
};

export const loginUserMutation = userDetails => {
  return axiosInstance.post(`${accountModule}/auth/login`, userDetails);
};

export const forgotPasswordMutation = email => {
  return axiosInstance.post(`${accountModule}/user/reset`, email);
};

export const passwordResetMutation = userDetails => {
  return axiosInstance.post(`${accountModule}/user/reset/verify`, userDetails);
};

export const createStoreMutation = async store => {
  return axiosInstance.post(`${storeModule}/create`, store);
};

export const updateStoreMutation = async store => {
  return axiosInstance.post(`${storeModule}/update/${store.id}`, store);
};

export const uploadFileMutation = async ({ file, cb, signal }) => {
  const formData = new FormData();
  formData.append("file", file);
  return axiosInstance.post(`${storeModule}/upload`, formData, {
    headers: { "Content-Type": "multipart/form-data", Accept: "*/*" },
    signal,
    onUploadProgress: event => cb && cb(event),
  });
};

export const verifyBankAccountMutation = async details => {
  return axiosInstance.post(
    `https://api.digible.one/v1/business/stores/bank/verify`,
    details,
  );
};

export const saveBankDetails = async accountDetails => {
  return axiosInstance.post(`${bankModule}/create`, accountDetails);
};

export const withdrawMoney = async details => {
  return axiosInstance.post(`${withdrawalModule}/create`, details);
};

export const createCart = async payload => {
  return axiosInstance.post(`${cartModule}/create/anonymous`, payload);
};

export const updateCart = async payload => {
  return axiosInstance.put(`${cartModule}/update`, payload);
};

export const createOrderMutation = async order => {
  return axiosInstance.post(`${orderModule}/create/anonymous`, order);
};

export const verifyOrderMutation = async payload => {
  return axiosInstance.post(`${orderModule}/verify`, payload);
};

export const AddProduct = async productDetails => {
  return axiosInstance.post(`${productModule}/create`, productDetails);
};

export const uploadImagesMutation = async ({ file, cb, signal }) => {
  const formData = new FormData();
  formData.append("file", file);
  return axiosInstance.post(`${storeModule}/upload`, formData, {
    headers: { "Content-Type": "multipart/form-data", Accept: "*/*" },
    signal,
    onUploadProgress: event => cb && cb(event),
  });
};

export const updateProduct = async ({ id, payload }) => {
  return axiosInstance.post(`${productModule}/update/${id}`, payload);
};

export const addCollection = async details => {
  return axiosInstance.post(`${storeModule}/collections`, details);
};

export const deleteCollection = ({ id, storeId }) => {
  return axiosInstance.delete(`${storeModule}/collections/${id}/${storeId}`);
};

export const updateCollection = payload => {
  return axiosInstance.put(`${storeModule}/collections`, payload);
};

export const updateSubscription = payload => {
  return axiosInstance.post(`${subscriptionModule}/subscribe`, payload);
};

export const cancelSubscription = payload => {
  return axiosInstance.delete(`${subscriptionModule}/cancel`, payload);
};

export const verifySubscription = payload => {
  return axiosInstance.post(`${subscriptionModule}/verify`, payload);
};

export const deleteProduct = (id) => {
  return axiosInstance.delete(`${productModule}/delete/${id}`);
};

export const addShippingProfile = payload => {
  return axiosInstance.post(`${storeModule}/shipping-profile`, payload);
};

export const deleteShippingProfile = id => {
  return axiosInstance.delete(`${storeModule}/shipping-profile/${id}`);
};

export const updateShippingProfile = payload => {
  return axiosInstance.put(`${storeModule}/shipping-profile`, payload);
};
